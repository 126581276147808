<template>
<v-container fluid>
  <v-flex>
    <v-card>
      <v-toolbar color="#0f2e8c" flat>
        <h3 class="white--text">Quản lý ngành hàng</h3>
        <v-spacer />
        <v-btn color="green" dark @click="boxAdd = true" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'">Thêm</v-btn>
      </v-toolbar>
      <v-card-title>
        <v-spacer />
        <v-text-field
            v-model="Search"
            label="Tìm kiếm"
            class="block"
        />
      </v-card-title>
      <v-card-text>
        <Loader :status="loading" class="mx-auto" />
        <v-data-iterator
            disable-sort
            :items="items"
            item-key="_id"
            :items-per-page="8"
            :loading="loading"
        >
          <template v-slot:default="{ items, isExpanded, expand }">
            <v-row>
              <v-col
                  v-for="item in items"
                  :key="item._id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
              >
                  <v-card>
                    <v-card-title>
                      {{item.Ten}}({{item.Ma}})
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            Tổng SP
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="text-h6 font-weight-bold">{{item.total}}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            Chờ nhập
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="text-h6 font-weight-bold">{{item.khoi_tao}}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            Tồn kho
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="text-h6 font-weight-bold">{{item.ton}}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            Đã bán
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="text-h6 font-weight-bold">{{item.da_ban}}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <template v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'">
                        <v-btn
                            icon
                            color="blue"
                            @click="doToggleEdit(item)"
                            class="mx-2"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="red"
                            @click="doDelete(item)"
                            class="mx-2"
                            v-if="item.total === 0"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <v-spacer />
                      <v-btn
                          v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn'"
                          dark
                          color="blue"
                          :to="`/kho-hang/kho/khoi-tao/${item._id}`"
                      >
                        Vào kho
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </v-flex>
  <v-dialog
    v-model="boxAdd"
    persistent
    max-width="800px"
  >
    <v-card flat>
      <v-card-title>
        {{ updateData.ID ? 'Sửa':'Thêm'}} Ngành Hàng
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="updateData.Ten"
              label="Tên ngành hàng"
              class="mx-auto"
              @change="doUpdateMa()"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="updateData.Ma"
                label="Mã ngành hàng"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="6">
            <v-textarea
                v-model="updateData.MoTa"
                label="Mô tả"
                class="mx-auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :dark="!createState"
          color="green"
          :loading="createState"
          :disabled="createState"
          @click="doCreate"
          v-if="updateData.ID === null"
        >
          Thêm mới
        </v-btn>
        <v-btn
            :dark="!createState"
            color="green"
            :loading="createState"
            :disabled="createState"
            @click="doUpdate"
            v-else
        >
          Cập nhật
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          @click="doCloseBoxAdd"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import {mapGetters} from "vuex";
import Query from '@/plugins/query/kho-nganh-hang.js';
import {Status} from "@/plugins/helper/dstatus";
import {initials} from "@/plugins/helper/string";
import Loader from '@/components/Loader.vue';
export default {
  name: "Nganh-Hang",
  components:{
    Loader,
  },
  computed:{
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
  },
  data(){
    return {
      notice: new Status(this.$swal),
      Search: null,
      boxAdd: false,
      createState: false,
      updateData:{
        ID:null,
        Ten: null,
        Ma: null,
        MoTa: null,
      },
      items:[],
      loading: false,
      headers: [
        { text: 'Tên', value: 'Ten', align: 'center',width: "20%",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Mã', value: 'Ma', align: 'center',width: "20%",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Mô tả', value: 'MoTa', align: 'center',width: "45%",class:"grey lighten-2 black--text",sortable:false },
        { text: '...', value: 'Action', align: 'center',width: "15%",class:"grey lighten-2 black--text",sortable:false },
      ],
    }
  },
  methods:{
    doDelete(item){
      const ID = item._id; const app = this;
      return this.notice.confirm("Xác nhận xóa ?", "Hành động này không thể phục hồi !!!").then(result=>{
        console.log(result);
        if(result){
          return Query.qrDelete(ID).then(()=>{
            app.notice.success("Đã xóa dữ liệu thành công !!!");
            return app.doDownload();
          })
        }
      });
    },
    doUpdate(){
      const {Ten, Ma, MoTa, ID} = this.updateData;
      if(!ID) return this.notice.error("Dữ liệu không hợp lệ !!!");
      if(!Ten && !Ma) return this.notice.error("Điền đầy đủ thông tin !!!");
      this.createState = true; const notice = this.notice; const app = this;
      return Query.qrUpdate(ID, {Ten, Ma, MoTa}).then(json=>{
        if(json.success){
          notice.success("Đã cập nhật ngành hàng !!!");
          app.doCloseBoxAdd();
          return app.doDownload();
        }
        if(json.error){
          app.notice.error(json.error);
        }
      }).finally(()=>app.createState=false);
    },
    doToggleEdit(data){
      const {_id, Ten, Ma, MoTa} = data;
      this.updateData = {
        ID:_id,
        Ten,
        Ma,
        MoTa
      };
      this.boxAdd = true;
    },
    doUpdateMa(){
      if(this.updateData.Ten){
        const Ma = initials(this.updateData.Ten);
        if(Ma && !this.updateData.Ma) this.updateData.Ma = Ma;
      }
    },
    doCreate(){
      const {Ten, Ma} = this.updateData;
      if(!Ten && !Ma) return this.notice.error("Điền đầy đủ thông tin !!!");
      this.createState = true; const notice = this.notice; const app = this;
      return Query.qrCreate(this.updateData).then(json=>{
        if(json.success){
          notice.success("Đã tạo mới ngành hàng !!!");
          app.doCloseBoxAdd();
          return app.doDownload();
        }
        if(json.error){
          app.notice.error(json.error);
        }
      }).finally(()=>app.createState=false);
    },
    doCloseBoxAdd(){
      this.boxAdd = false;
      this.updateData = {
        ID:null,
        Ten: null,
        Ma: null,
        MoTa: null,
      }
    },
    doDownload(){
      this.loading = true;
      return Query.qrList().then(json=>{
        if(json.data) this.items = json.data;
      }).finally(()=>this.loading=false);
    },

  },
  mounted() {
    this.doDownload();
  }
}
</script>

<style scoped>

</style>