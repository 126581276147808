import { Request } from '@/plugins/helper/fetch'
const req = new Request('kho/nganh-hang');
const qrList = ()=>req.fetch(`list`).then(data=>data.json());
const qrCreate = ({Ten, Ma, MoTa}) => req.put(`create`, {Ten, Ma, MoTa}).then(data=>data.json());
const qrUpdate = (id, {Ten, Ma, MoTa}) => req.patch(`update/${id}`,{Ten, Ma, MoTa}).then(data=>data.json());
const qrDelete = (id) => req.remove(`/delete/${id}`).then(data=>data.json());
export default {
  qrList,
  qrCreate,
  qrUpdate,
  qrDelete,
}